import { useMemo, useRef, useState } from 'react';
import {
  Col, Row, Image, Button, Alert,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import SEO from '../components/SEO';
import IMG_E8932 from '../images/subscribe/IMG_E8932.jpg';
import { unsubscribeByEmail } from '../api/SubscribeAPI';

function Subscribe(props) {
  const { location } = props;
  const searchParams = new URLSearchParams(location.search);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHiddenBtn, setIsHiddenBtn] = useState(false);

  const email = useMemo(() => searchParams.get('email'), [searchParams]);

  const refIsSubmitting = useRef(false);

  const handleUnsubscribeClick = () => {
    if (refIsSubmitting.current) {
      return true;
    }

    if (!searchParams.has('email')) {
      return toast.dark('Can not find your email address! 🤔');
    }

    setIsSubmitting(true);
    refIsSubmitting.current = true;
    unsubscribeByEmail(email)
      .then(({ data }) => {
        if (data.status === 'success') {
          setIsHiddenBtn(true);
          return toast.dark('Your unsubscription is successful!');
        }
        return toast.dark('Can not find your email address! 🤔');
      })
      .catch(() => {
        return toast.dark(
          "Oops! There's something wrong, please try it later. If it's still not working, please let us know. 🙏",
        );
      })
      .then(() => {
        setIsSubmitting(false);
        refIsSubmitting.current = false;
      });

    return true;
  };

  return (
    <>
      <div className="bg-dark text-light vh-100">
        <SEO title="Unsubscription" />
        <Row className="text-center container mx-auto py-5">
          <Col>
            <Row>
              <Col>
                <h1>Unsubscription</h1>
              </Col>
            </Row>
            <Row>
              <Col xs lg="8" className="mx-auto">
                <p>
                  {`Click on "Unsubscribe" to stop receiving emails from Waltty
                Playing Card Stduio on this email address.`}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs lg="8" className="mx-auto">
                {isHiddenBtn ? (
                  <Alert variant="success">
                    Your unsubscription is successful!
                  </Alert>
                ) : (
                  <>
                    <p className="fw-bold fs-5">{email}</p>
                    <div className="d-grid mt-2">
                      <Button
                        variant="secondary"
                        disabled={isSubmitting}
                        onClick={handleUnsubscribeClick}
                      >
                        Unsubscribe
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <Row className="mt-2">
              <Col xs lg="8" className="mx-auto">
                <Image
                  fluid
                  src={IMG_E8932}
                  alt="Aura playing cards by Waltty playing card studio"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </>
  );
}

Subscribe.defaultProps = {
  location: {},
};

Subscribe.propTypes = {
  location: PropTypes.shape({ search: PropTypes.shape({}) }),
};

export default Subscribe;
